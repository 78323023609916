import {MessageName, Query, QueryHandler} from '@modules/messaging/query';

const VIEW_LOCALE: MessageName = 'view_locale';

interface ViewLocale extends Query {
  readonly name: typeof VIEW_LOCALE;
}

interface ViewLocaleFunction {
  (): ViewLocale;
}

export interface ViewLocaleView {
  (): Promise<string>;
}

export type ViewLocaleHandler = QueryHandler<ViewLocale, string>;

interface ViewLocaleHandlerFunction {
  (view: ViewLocaleView): ViewLocaleHandler;
}

export const viewLocale: ViewLocaleFunction = () => ({name: VIEW_LOCALE});

export const viewLocaleHandlerFunction: ViewLocaleHandlerFunction = view => async () => view();
