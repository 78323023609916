import {viewCustomerHandlerFunction, ViewCustomerView} from '../../projection/viewCustomer';
import {viewUserHandler} from '../../../../user/core/infrastructure/projection/viewUser';
import {viewUser} from '../../../../user/core/projection/viewUser';
import {viewCountryHandler} from '../../../../locale/core/infrastructure/projection/viewCountry';
import {viewCountry} from '../../../../locale/core/projection/viewCountry';
import {Customer} from '../../projection/customer';

const viewCustomerView: ViewCustomerView = async () => {
  const user = await viewUserHandler(viewUser());

  if (!user || !user.id) {
    return null;
  }

  const country = await viewCountryHandler(viewCountry());

  const customer: Customer = {
    customerId: user.id,
    segment: 'WOMEN',
    country,
  };

  return customer;
};

export const viewCustomerHandler = viewCustomerHandlerFunction(viewCustomerView);
