import {MessageName, Query, QueryHandler} from '@modules/messaging/query';
import {User} from './user';

const VIEW_USER_NAME: MessageName = 'view_user';

interface ViewUser extends Query {
  readonly name: typeof VIEW_USER_NAME;
}

interface ViewUserFunction {
  (): ViewUser;
}

export interface ViewUserView {
  (): Promise<User | null>;
}

export type ViewUserHandler = QueryHandler<ViewUser, User | null>;

interface ViewUserHandlerFunction {
  (view: ViewUserView): ViewUserHandler;
}

export const viewUser: ViewUserFunction = () => ({name: VIEW_USER_NAME});

export const viewUserHandlerFunction: ViewUserHandlerFunction = view => async () => view();
