import {MessageName, Query, QueryHandler} from '@modules/messaging/query';
import {Customer} from './customer';

const VIEW_CUSTOMER: MessageName = 'view_customer';

interface ViewCustomer extends Query {
  readonly name: typeof VIEW_CUSTOMER;
}

interface ViewCustomerFunction {
  (): ViewCustomer;
}

type ViewCustomerResult = Customer | null;

export interface ViewCustomerView {
  (): Promise<ViewCustomerResult>;
}

export type ViewCustomerHandler = QueryHandler<ViewCustomer, ViewCustomerResult>;

interface ViewCustomerHandlerFunction {
  (view: ViewCustomerView): ViewCustomerHandler;
}

export const viewCustomer: ViewCustomerFunction = () => ({name: VIEW_CUSTOMER});

export const viewCustomerHandlerFunction: ViewCustomerHandlerFunction = view => async () => view();
