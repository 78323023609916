import {UserApplication} from '@application/User';
import {viewUserHandlerFunction, ViewUserView} from '../../projection/viewUser';

const viewUserView: ViewUserView = async () => {
  try {
    return await UserApplication.getUserOverallInfo();
  } catch (error) {
    return null;
  }
};

export const viewUserHandler = viewUserHandlerFunction(viewUserView);
