import {Country} from '@domain/model/Site/Locale';
import {MessageName, Query, QueryHandler} from '@modules/messaging/query';

const VIEW_COUNTRY: MessageName = 'view_country';

interface ViewCountry extends Query {
  readonly name: typeof VIEW_COUNTRY;
}

interface ViewCountryFunction {
  (): ViewCountry;
}

export interface ViewCountryView {
  (): Promise<Country>;
}

export type ViewCountryHandler = QueryHandler<ViewCountry, Country>;

interface ViewCountryHandlerFunction {
  (view: ViewCountryView): ViewCountryHandler;
}

export const viewCountry: ViewCountryFunction = () => ({name: VIEW_COUNTRY});

export const viewCountryHandlerFunction: ViewCountryHandlerFunction = view => async () => view();
