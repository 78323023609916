export enum Environment {
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
}
export interface Configuration {
  assetsPath: string;
  commonAssetsPath: string;
  environment: Environment;
  googleWebId: string;
  kameleoonApiKey: string;
  paymentAssetsPath: string;
  shopAssetsPath: string;
  stripeKey: string;
  stripeUKKey: string;
}
