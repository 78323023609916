import {LocationApplication} from '@application/Site';
import {viewCountryHandlerFunction, ViewCountryView} from '@modules/locale/core/projection/viewCountry';

const viewCountryView: ViewCountryView = async () => {
  const {country} = await LocationApplication.getLocale();

  return country;
};

export const viewCountryHandler = viewCountryHandlerFunction(viewCountryView);
