import {getClient, Client} from './errorLogger';

/**
 * We are storing a module variable to be stable and unique.
 * This module should'nt be imported before ErrorHandlerProvider is mounted
 */
let rootClient: Client | undefined;

const sentryRootClient: () => Client | undefined = () => {
  if (!rootClient) {
    rootClient = getClient();
  }

  return rootClient;
};

export {sentryRootClient};
