import React, {FC, useEffect, useMemo, useState} from 'react';
import {viewLocaleHandler} from '../../../../locale/core/infrastructure/projection/viewLocale';
import {viewLocale} from '../../../../locale/core/projection/viewLocale';
import {viewCustomerHandler} from '../../../../customer/core/infrastructure/projection/viewCustomer';
import {viewCustomer} from '../../../../customer/core/projection/viewCustomer';
import {Customer} from '../../../../customer/core/projection/customer';
import {nextBestActionRoot} from '../../../bootstrap';
import {sentryRootClient} from '../../../../../errors/sentryRootClient';
import {NextBestActionRootComponents} from '@lookiero/style-profile/dist/src/infrastructure/ui/integration/nextBestAction/NextBestActionRoot';
import {Customer as StyleProfileCustomer, Locale as StyleProfileLocale} from '@lookiero/style-profile';
import {iOSInAppBrowserBannerHeight, iOSInAppBrowserVideoAd} from '@helpers';

interface StyleProfileNextBestActionHostProps {
  readonly children: JSX.Element;
}

const StyleProfileNextBestActionHost: FC<StyleProfileNextBestActionHostProps> = ({children}) => {
  const [customer, setCustomer] = useState<Customer | null>();
  const [locale, setLocale] = useState<string>();

  useEffect(() => {
    const loadDependencies = async (): Promise<void> => {
      setLocale(await viewLocaleHandler(viewLocale()));
      setCustomer(await viewCustomerHandler(viewCustomer()));
    };

    loadDependencies();
  }, []);

  const {NextBestActionRootHost} = useMemo(
    () =>
      customer
        ? nextBestActionRoot({customerId: customer.customerId, rootClient: sentryRootClient()})
        : ({} as NextBestActionRootComponents),
    [customer],
  );

  return locale && customer ? (
    <NextBestActionRootHost
      customer={customer as StyleProfileCustomer}
      locale={locale as StyleProfileLocale}
      iOSInAppBrowserVideoAd={iOSInAppBrowserVideoAd}
      iOSInAppBrowserVideoAdBannerHeight={iOSInAppBrowserBannerHeight}
    >
      {children}
    </NextBestActionRootHost>
  ) : (
    children
  );
};

export {StyleProfileNextBestActionHost};
